import { isUndefined } from 'lodash'
import axiosClient from 'src/shared/utils/axios'
import { FrontEndError } from 'src/shared/utils/error'

interface IUpdateUser {
    username?: string
    city?: string
    country?: string
    DOB?: string
    phoneNumber?: string
    firstname?: string
    lastname?: string
    zipcode?: string
    unit?: string
    street_address?: string
    company?: string

    oldPassword?: string
    password?: string
    confirmNewPassword?: string

    notFirstSeen?: boolean

    discordWebhook?: string
}

class UserApi {
    async updateUser({
        username,
        city,
        country,
        DOB,
        phoneNumber,
        firstname,
        lastname,
        zipcode,
        unit,
        street_address,
        company,

        oldPassword,
        password,
        confirmNewPassword,

        notFirstSeen,

        discordWebhook,
    }: IUpdateUser) {
        const url = `api/user/update/info`

        try {
            const update = {
                ...(!isUndefined(username) ? { username } : {}),
                ...(!isUndefined(city) ? { city } : {}),
                ...(!isUndefined(country) ? { country } : {}),
                ...(!isUndefined(DOB) ? { DOB } : {}),
                ...(!isUndefined(phoneNumber) ? { phoneNumber } : {}),
                ...(!isUndefined(firstname) ? { firstname } : {}),
                ...(!isUndefined(lastname) ? { lastname } : {}),
                ...(!isUndefined(zipcode) ? { zipcode } : {}),
                ...(!isUndefined(unit) ? { unit } : {}),
                ...(!isUndefined(street_address) ? { street_address } : {}),
                ...(!isUndefined(company) ? { company } : {}),

                ...(!isUndefined(oldPassword) ? { oldPassword } : {}),
                ...(!isUndefined(password) ? { password } : {}),
                ...(!isUndefined(confirmNewPassword) ? { confirmNewPassword } : {}),

                ...(!isUndefined(notFirstSeen) ? { notFirstSeen } : {}),

                ...(!isUndefined(discordWebhook) ? { discordWebhook } : {}),
            }
            const result = await axiosClient().put(url, update)
            return result
        } catch (err) {
            FrontEndError(err)
            return new Error(err.response?.data?.message || 'Internal server error')
        }
    }

    async getPlan(planId: string) {
        const url = `/api/payment-plan/get/${planId}`
        try {
            const result = await axiosClient().get(url)
            return result.data
        } catch (err) {
            FrontEndError(err)
            return new Error(err.response?.data?.message || 'Internal server error')
        }
    }

    async getStats() {
        const url = `/api/user/dashboard-stats`
        try {
            const result = await axiosClient().get(url)
            return result.data
        } catch (err) {
            FrontEndError(err)
            return new Error(err.response?.data?.message || 'Internal server error')
        }
    }

    async saveFilter(type: string, name: string, filter: any) {
        const url = `/api/user/save-filter`
        try {
            const result = await axiosClient().post(url, { type, name, filter })
            return result.data
        } catch (err) {
            FrontEndError(err)
            return new Error(err.response?.data?.message || 'Internal server error')
        }
    }

    async getSavedFilters(type: string) {
        const url = `/api/user/get-filters?type=${type}`
        try {
            const result = await axiosClient().get(url)
            return result.data
        } catch (err) {
            FrontEndError(err)
            return new Error(err.response?.data?.message || 'Internal server error')
        }
    }

    async removeFilter(filterId: string) {
        const url = `/api/user/remove-filter?filterId=${filterId}`
        try {
            const result = await axiosClient().delete(url)
            return result.data
        } catch (err) {
            FrontEndError(err)
            return new Error(err.response?.data?.message || 'Internal server error')
        }
    }

    async getChildren() {
        const url = `/api/user/get-children`
        try {
            const result = await axiosClient().get(url)
            return result.data
        } catch (err) {
            FrontEndError(err)
            return new Error(err.response?.data?.message || 'Internal server error')
        }
    }

    async addChild({ fullname, username, country }: { fullname: string; username: string; country: string }) {
        const url = `/api/user/add-child`
        try {
            const result = await axiosClient().post(url, { fullname, username, country })
            return result.data
        } catch (err) {
            return new Error(err.response?.data?.message || 'Internal server error')
        }
    }

    async removeChild({ childId }: { childId: string }) {
        const url = `/api/user/remove-child?child_id=${childId}`
        try {
            const result = await axiosClient().delete(url)
            return result.data
        } catch (err) {
            return new Error(err.response?.data?.message || 'Internal server error')
        }
    }

    async setActivateChild({ childId, activate }: { childId: string; activate: boolean }) {
        const url = `/api/user/activate-child?child_id=${childId}&activate=${activate}`
        try {
            const result = await axiosClient().post(url)
            return result.data
        } catch (err) {
            return new Error(err.response?.data?.message || 'Internal server error')
        }
    }
}

export const userApi = new UserApi()
