import { ISliderValueProps } from './types/local/sliderValueProps'

export const THEMES = {
    LIGHT: 'LIGHT',
    DARK: 'DARK',
    NATURE: 'NATURE',
}
export const DEFAULT_SLIDER_VALUE: ISliderValueProps = {
    spotify: [0, 100],
    capacity: [0, 100000],
    price: [0, 5000],
    primary: [0, 30000],
    secondary: [0, 30000],
    soldPercentage: [0, 100],
}
