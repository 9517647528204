const PATHS = {
    ROOT: '/',
    NOT_FOUND: '/404',
    MAINTENANCE: '/maintenance',
    NO_CONNECTION: '/no-connection',
    AUTHENTICATION: {
        ROOT: '/authentication',
        LOGIN: '/authentication/login',
        PASSWORD_RECOVERY: '/authentication/password-recovery',
        PASSWORD_RESET: '/authentication/password-reset',
        REGISTER: '/authentication/register',
        VERIFY_CODE: '/authentication/verify-code',
        MESSAGE_SENT: '/authentication/message-sent',
    },
    DASHBOARD: {
        ROOT: '/dashboard',
        USER: '/dashboard/user',
        EVENT_ANALYTICS: '/dashboard/event_analytics',
        ANALYTICS: '/dashboard/analytics',
        PRIMARY_TOP_MOVERS: '/dashboard/primary-top-movers',
        SECONDARY_TOP_MOVERS: '/dashboard/secondary-top-movers',
        PRIMARY_FAVORITES: '/dashboard/primary-favorites',
        PRIMARY_ANALYTICS: '/dashboard/primary-analytics/:id',
        SECONDARY_FAVORITES: '/dashboard/secondary-favorites',
        SECONDARY_TOP_SELLING: '/dashboard/secondary-top-selling',
        SECONDARY_ANALYTICS: '/dashboard/secondary-analytics/:id',
        UPCOMING: {
            ROOT: '/dashboard/upcoming',
            WATCHLIST: '/dashboard/upcoming/watchlist',
            BUYING_LIST: '/dashboard/upcoming/buying_list',
        },
        METRIC: {
            ROOT: '/dashboard/metric',
            ID: '/dashboard/metric/:id',
        },
        EXTENSION: '/dashboard/extension',
        ALERTS: '/dashboard/alerts',
        ACADEMY: '/dashboard/academy',
        SUPPORT: '/dashboard/support',
        COMMUNITY: '/dashboard/community',
        ADMIN: {
            ROOT: '/dashboard/admin',
            SUBSCRIPTION_MANAGEMENT: '/dashboard/admin/subscription-management',
            TIER_MANAGEMENT: '/dashboard/admin/tier-management',
            VENUE_MATCH: '/dashboard/admin/venue-match',
            EVENT_MATCH: '/dashboard/admin/event-match',
            VENUE_MISMATCH: '/dashboard/admin/venue-mismatch',
            SEAT_GROUPS: '/dashboard/admin/seat-groups',
            VENUE_URLS: '/dashboard/admin/add-venue-urls',
            BLACKLIST: '/dashboard/admin/add-black-list-words',
            SCRAPE_VENUE: '/dashboard/admin/scrape-venue',
            HOT_SECTIONS: '/dashboard/admin/hot-sections',
        },
    },
}

export default PATHS
