import { Suspense, lazy } from 'react'
import { Navigate, Outlet, RouteObject } from 'react-router'
import { JSX } from 'react/jsx-runtime'

import DashboardLayout from '../components/dashboard/layout/DashboardLayout'
import AuthGuard from '../components/general/AuthGuard'
import GuestGuard from '../components/general/GuestGuard'
import LoadingScreen from '../components/general/LoadingScreen'
import MessageSent from './authentication/MessageSent'
import PATHS from '../common/constants/paths'

const Loadable = (Component) => (props: JSX.IntrinsicAttributes) => (
    <Suspense fallback={<LoadingScreen />}>
        <Component {...props} />
    </Suspense>
)

// Common
const NotFound = Loadable(lazy(() => import('./common/NotFound')))
const ServerError = Loadable(lazy(() => import('./common/ServerError')))
const NoConnection = Loadable(lazy(() => import('./common/NoConnection')))

// Authentication
const Login = Loadable(lazy(() => import('./authentication/Login')))
const PasswordRecovery = Loadable(lazy(() => import('./authentication/PasswordRecovery')))
const PasswordReset = Loadable(lazy(() => import('./authentication/PasswordReset')))
const Register = Loadable(lazy(() => import('./authentication/Register')))
const VerifyCode = Loadable(lazy(() => import('./authentication/VerifyCode')))

// Upcoming
const Overview = Loadable(lazy(() => import('./upcoming/upcoming-sales/UpcomingSalesPage')))
const WatchList = Loadable(lazy(() => import('./upcoming/WatchList')))
const BuyingList = Loadable(lazy(() => import('./upcoming/buying-list')))
const SecondaryTopSelling = Loadable(lazy(() => import('./upcoming/SecondaryTopSelling')))

// Analytics
const EventAnalytics = Loadable(lazy(() => import('./event-analytics/EventAnalyticsPage')))
const PrimaryAnalytics = Loadable(lazy(() => import('./event-analytics/PrimaryAnalytics')))
const PrimaryFavorites = Loadable(lazy(() => import('./event-analytics/PrimaryFavorites')))
const PrimaryTopMovers = Loadable(lazy(() => import('./event-analytics/PrimaryTopMovers')))
const SecondaryAnalytics = Loadable(lazy(() => import('./event-analytics/SecondaryAnalytics')))
const SecondaryFavorites = Loadable(lazy(() => import('./event-analytics/SecondaryFavorites')))
const SecondaryTopMovers = Loadable(lazy(() => import('./event-analytics/SecondaryTopMovers')))

// Artist
const ArtistMetric = Loadable(lazy(() => import('./artists/ArtistMetric')))
const Metric = Loadable(lazy(() => import('./artists/Metric')))

// User
const UserDashboard = Loadable(lazy(() => import('./user/dashboard')))

// Dashboard
const ExtensionPage = Loadable(lazy(() => import('./dashboard/extension')))
const AlertsPage = Loadable(lazy(() => import('./dashboard/alerts')))
const AcademyPage = Loadable(lazy(() => import('./dashboard/academy')))
const SupportPage = Loadable(lazy(() => import('./dashboard/support')))
const CommunityPage = Loadable(lazy(() => import('./dashboard/community')))

// Admin
const Admin = Loadable(lazy(() => import('./admin/Admin')))
const SubscriptionManagement = Loadable(lazy(() => import('./admin/SubscriptionManagement')))
const TierManagement = Loadable(lazy(() => import('./admin/TierManagement')))
const VenueMatch = Loadable(lazy(() => import('./admin/VenueMatch')))
const EventMatch = Loadable(lazy(() => import('./admin/EventMatch')))
const VenueMismatch = Loadable(lazy(() => import('./admin/VenueMismatch')))
const SeatGroups = Loadable(lazy(() => import('./admin/SeatGroups')))
const VenueUrls = Loadable(lazy(() => import('./admin/VenueUrls')))
const Blacklist = Loadable(lazy(() => import('./admin/Blacklist')))
const ScrapeVenue = Loadable(lazy(() => import('./admin/ScrapeVenue')))
const HotSections = Loadable(lazy(() => import('./admin/HotSections')))

const routes: RouteObject[] = [
    {
        path: `${PATHS.ROOT}`,
        element: <Navigate to={`${PATHS.DASHBOARD.UPCOMING.ROOT}`} replace />,
    },
    {
        path: '/*',
        element: <Navigate to={`${PATHS.NOT_FOUND}`} replace />,
    },
    {
        path: `${PATHS.NOT_FOUND}`,
        element: <NotFound />,
    },
    {
        path: `${PATHS.MAINTENANCE}`,
        element: <ServerError />,
    },
    {
        path: `${PATHS.NO_CONNECTION}`,
        element: <NoConnection />,
    },
    {
        path: `${PATHS.AUTHENTICATION.ROOT}`,
        element: (
            <GuestGuard>
                <Outlet />
            </GuestGuard>
        ),
        children: [
            { path: `${PATHS.AUTHENTICATION.LOGIN}`, element: <Login /> },
            { path: `${PATHS.AUTHENTICATION.PASSWORD_RECOVERY}`, element: <PasswordRecovery /> },
            { path: `${PATHS.AUTHENTICATION.PASSWORD_RESET}`, element: <PasswordReset /> },
            { path: `${PATHS.AUTHENTICATION.REGISTER}`, element: <Register /> },
            { path: `${PATHS.AUTHENTICATION.VERIFY_CODE}`, element: <VerifyCode /> },
            { path: `${PATHS.AUTHENTICATION.MESSAGE_SENT}`, element: <MessageSent /> },
        ],
    },
    {
        path: `${PATHS.DASHBOARD.ROOT}`,
        element: (
            <AuthGuard>
                <DashboardLayout />
            </AuthGuard>
        ),
        children: [
            { path: `${PATHS.DASHBOARD.USER}`, element: <UserDashboard /> },
            { path: `${PATHS.DASHBOARD.EVENT_ANALYTICS}`, element: <EventAnalytics /> },
            { path: `${PATHS.DASHBOARD.ANALYTICS}`, element: <ArtistMetric /> },
            { path: `${PATHS.DASHBOARD.PRIMARY_TOP_MOVERS}`, element: <PrimaryTopMovers /> },
            { path: `${PATHS.DASHBOARD.SECONDARY_TOP_MOVERS}`, element: <SecondaryTopMovers /> },
            { path: `${PATHS.DASHBOARD.SECONDARY_TOP_SELLING}`, element: <SecondaryTopSelling /> },
            { path: `${PATHS.DASHBOARD.PRIMARY_FAVORITES}`, element: <PrimaryFavorites /> },
            { path: `${PATHS.DASHBOARD.PRIMARY_ANALYTICS}`, element: <PrimaryAnalytics /> },
            { path: `${PATHS.DASHBOARD.SECONDARY_FAVORITES}`, element: <SecondaryFavorites /> },
            { path: `${PATHS.DASHBOARD.SECONDARY_ANALYTICS}`, element: <SecondaryAnalytics /> },
            {
                path: `${PATHS.DASHBOARD.UPCOMING.ROOT}`,
                element: (
                    <AuthGuard>
                        <Outlet />
                    </AuthGuard>
                ),
                children: [
                    { path: `${PATHS.DASHBOARD.UPCOMING.ROOT}`, element: <Overview /> },
                    { path: `${PATHS.DASHBOARD.UPCOMING.WATCHLIST}`, element: <WatchList /> },
                    { path: `${PATHS.DASHBOARD.UPCOMING.BUYING_LIST}`, element: <BuyingList /> },
                ],
            },
            {
                path: `${PATHS.DASHBOARD.METRIC.ROOT}`,
                element: (
                    <AuthGuard>
                        <Outlet />
                    </AuthGuard>
                ),
                children: [
                    { path: `${PATHS.DASHBOARD.METRIC.ROOT}`, element: <ArtistMetric /> },
                    { path: `${PATHS.DASHBOARD.METRIC.ID}`, element: <Metric /> },
                ],
            },
            {
                path: `${PATHS.DASHBOARD.EXTENSION}`,
                element: (
                    <AuthGuard>
                        <Outlet />
                    </AuthGuard>
                ),
                children: [{ path: `${PATHS.DASHBOARD.EXTENSION}`, element: <ExtensionPage /> }],
            },
            {
                path: `${PATHS.DASHBOARD.ALERTS}`,
                element: (
                    <AuthGuard>
                        <Outlet />
                    </AuthGuard>
                ),
                children: [{ path: `${PATHS.DASHBOARD.ALERTS}`, element: <AlertsPage /> }],
            },
            {
                path: `${PATHS.DASHBOARD.ACADEMY}`,
                element: (
                    <AuthGuard>
                        <Outlet />
                    </AuthGuard>
                ),
                children: [{ path: `${PATHS.DASHBOARD.ACADEMY}`, element: <AcademyPage /> }],
            },
            {
                path: `${PATHS.DASHBOARD.SUPPORT}`,
                element: (
                    <AuthGuard>
                        <Outlet />
                    </AuthGuard>
                ),
                children: [{ path: `${PATHS.DASHBOARD.SUPPORT}`, element: <SupportPage /> }],
            },
            {
                path: `${PATHS.DASHBOARD.COMMUNITY}`,
                element: (
                    <AuthGuard>
                        <Outlet />
                    </AuthGuard>
                ),
                children: [{ path: `${PATHS.DASHBOARD.COMMUNITY}`, element: <CommunityPage /> }],
            },
            {
                path: `${PATHS.DASHBOARD.ADMIN.ROOT}`,
                element: (
                    <AuthGuard>
                        <Outlet />
                    </AuthGuard>
                ),
                children: [
                    { path: `${PATHS.DASHBOARD.ADMIN.ROOT}`, element: <Admin /> },
                    { path: `${PATHS.DASHBOARD.ADMIN.SUBSCRIPTION_MANAGEMENT}`, element: <SubscriptionManagement /> },
                    { path: `${PATHS.DASHBOARD.ADMIN.TIER_MANAGEMENT}`, element: <TierManagement /> },
                    { path: `${PATHS.DASHBOARD.ADMIN.VENUE_MATCH}`, element: <VenueMatch /> },
                    { path: `${PATHS.DASHBOARD.ADMIN.EVENT_MATCH}`, element: <EventMatch /> },
                    { path: `${PATHS.DASHBOARD.ADMIN.VENUE_MISMATCH}`, element: <VenueMismatch /> },
                    { path: `${PATHS.DASHBOARD.ADMIN.SEAT_GROUPS}`, element: <SeatGroups /> },
                    { path: `${PATHS.DASHBOARD.ADMIN.VENUE_URLS}`, element: <VenueUrls /> },
                    { path: `${PATHS.DASHBOARD.ADMIN.BLACKLIST}`, element: <Blacklist /> },
                    { path: `${PATHS.DASHBOARD.ADMIN.SCRAPE_VENUE}`, element: <ScrapeVenue /> },
                    { path: `${PATHS.DASHBOARD.ADMIN.HOT_SECTIONS}`, element: <HotSections /> },
                ],
            },
        ],
    },
]

export default routes
